import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CourseService{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/course';
    }

    paginate(data, index = null) {
        let url = `${this.#api}`;
        var esc = encodeURIComponent;
        if (data != null && data != undefined && data != "") {
        var queryString = Object.keys(data)
            .map(k => esc(k) + '=' + esc(data[k]))
            .join('&');
            url = url + '?' + queryString;
            if (index)
                url = url + "&page=" + index
        } else {
            url = url + "?page=" + index
        }
        return apiService.get(url);
    }

    show(id){
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    update(id,data) {
        let url = `${this.#api}/${id}/update`;
        return apiService.post(url,data)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getByGrade(gradeId)
    {
        let url = `${this.#api}/${gradeId}/grade`;
        return apiService.get(url)
    }
}