import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CategoryService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/sub-category';
    }
    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }
    paginate(data=null,index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index != null && !queryString)
            url = url + '?' + "page=" + (index);
        else
            url = url + '&' + "page=" + (index);
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}/update`;
        return apiService.post(url, data)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    getByCategory(categoryId)
    {
        let url = `${this.#api}/${categoryId}/category`
        return apiService.get(url)
    }
    get(){
        let url = `${this.#api}/all/active`;
        return apiService.get(url)
    }
}