<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  <router-link to="/course">
                    <i class="fa fa-arrow-left"></i>
                  </router-link>
                  Add Course
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Course
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="createCourse()">
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <h5 class="mx-3">General Info</h5>
                    <v-col cols="12">
                      <v-text-field
                          label="Title *"
                          v-model="course.title"
                          outlined
                          dense
                      >
                      </v-text-field>
                      <div
                          class="text-danger"
                          v-if="$v.course.title.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.title" >*{{errors.title[0]}}</span>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          label="Subject Code *"
                          v-model="course.subject_code"
                          outlined
                          dense
                      >
                      </v-text-field>
                      <div
                          class="text-danger"
                          v-if="$v.course.subject_code.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.subject_code" >*{{errors.subject_code[0]}}</span>
                    </v-col>


                    <v-col cols="12">
                      <v-file-input v-model="course.image"
                                    outlined
                                    class="mr-3"
                                    dense
                                    ref="fileInput"
                                    @change="previewImage"
                                    placeholder="Logo"
                                    append-outer-icon="mdi-camera"
                                    label="Image" prepend-icon="mdi-paperclip">
                        <template v-slot:selection="{ text }">
                          <v-chip small label color="primary">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                      <div
                          class="text-danger"
                          v-if="$v.course.image.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.image" >*{{errors.image[0]}}</span>
                      <img id="logo_preview" v-if="imgurl" :src="imgurl" class="img-thumbnail ml-5 mt-2">
                    </v-col>

                    <v-col cols="12">
                      <label for="description">Description*</label>
                      <div
                          class="text-danger"
                          v-if="$v.course.description.$error"
                      >
                        This information is required
                      </div>
                      <div class="text-danger" v-if="errors.description" >*{{errors.description[0]}}</div>
                      <ckeditor
                          :config="editorConfig"
                          v-model="course.description" >
                      </ckeditor>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row>
                    <h5 class="mx-3">Additional Info</h5>
                    
                    <v-col cols="12">
                      <v-select
                          label="Expiry date (In month) *"
                          v-model="course.expiry_months"
                          :items="months"
                          outlined
                          dense
                      >
                      </v-select>
                      <div
                          class="text-danger"
                          v-if="$v.course.expiry_months.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.expiry_months" >*{{errors.expiry_months[0]}}</span>
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          label="Category *"
                          clearable
                          @change="getSubCategory"
                          v-model="course.category_id"
                          deletable-chips
                          dense
                          outlined
                      ></v-autocomplete>
                      <div
                          class="text-danger"
                          v-if="$v.course.category_id.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.category_id" >*{{errors.category_id[0]}}</span>
                    </v-col>
                    
                    <v-col cols="12">
                      <v-autocomplete
                          :items="sub_categories"
                          item-text="name"
                          item-value="id"
                          @change="getAllGrades"
                          label="Syllabus *"
                          clearable
                          v-model="course.sub_category_id"
                          deletable-chips dense outlined
                      ></v-autocomplete>
                      <div
                          class="text-danger"
                          v-if="$v.course.sub_category_id.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.sub_category_id" >*{{errors.sub_category_id[0]}}</span>
                    </v-col>
                    
                    <v-col cols="12">
                      <v-autocomplete
                          :items="grades"
                          item-text="title"
                          item-value="id"
                          label="Grade *"
                          clearable v-model="course.grade_id"
                          deletable-chips
                          dense
                          outlined
                      ></v-autocomplete>
                      <div
                          class="text-danger"
                          v-if="$v.course.grade_id.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.grade_id" >*{{errors.grade_id[0]}}</span>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                          label="Default Price *"
                          v-model="course.default_price"
                          outlined
                          dense
                      >
                      </v-text-field>
                      <div
                          class="text-danger"
                          v-if="$v.course.default_price.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.default_price" >*{{errors.default_price[0]}}</span>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                          label="Position"
                          v-model="course.position"
                          outlined
                          dense
                      >
                      </v-text-field>
                      <div
                          class="text-danger"
                          v-if="$v.course.position.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.position" >*{{errors.position[0]}}</span>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          label="Rising Software Key"
                          v-model="course.rising_software_key"
                          outlined
                          dense
                      >
                      </v-text-field>
                      <div
                          class="text-danger"
                          v-if="$v.course.rising_software_key.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.rising_software_key" >*{{errors.rising_software_key[0]}}</span>
                    </v-col>
                    
                    <v-col cols="12">
                      <div
                          class="text-danger"
                          v-if="$v.course.test_type.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.test_type" >*{{errors.test_type[0]}}</span>
                      <label>Test Type</label>
                      <v-radio-group
                          v-model="course.test_type"
                          row
                      >
                        <v-radio
                            label="Lesson"
                            value="lesson"
                        ></v-radio>
                        <v-radio
                            label="Course"
                            value="course"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" md="4">
                  <span class="text-danger" v-if="errors.state_price" >*{{errors.state_price[0]}}</span>
                  <v-switch
                      v-model="course.state_price"
                      :label="`State Price` + ' : ' + statePriceLabel"
                      color="indigo darken-3"
                  ></v-switch>
                </v-col>
                <v-col cols="6" md="4">
                  <span class="text-danger" v-if="errors.availiability_state" >*{{errors.availiability_state[0]}}</span>
                  <v-switch
                      v-model="course.availiability_state"
                      :label="`Availability Status` + ' : ' + availabilityStateLabel"
                      color="indigo darken-3"
                  ></v-switch>
                </v-col>
                <v-col cols="6" md="4">
                  <span class="text-danger" v-if="errors.visibility" >*{{errors.visibility[0]}}</span>
                  <v-switch
                      v-model="course.visibility"
                      :label="`Visibility` + ' : ' + visibilityLabel"
                      color="indigo darken-3"
                  ></v-switch>
                </v-col>
                <v-col cols="6" md="4">
                  <span class="text-danger" v-if="errors.status" >*{{errors.status[0]}}</span>
                  <v-switch
                      v-model="course.status"
                      :label="`Status` + ' : ' + statusLabel"
                      color="indigo darken-3"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr>
              <v-row>
                <v-col cols="12 text-right">
                  <v-btn
                    type="submit"
                    class="btn btn-primary text-white"
                  ><i class="fa fa-save"></i> Save</v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CourseService from "@/services/product/course/CourseService";
import GradeService from "@/services/product/grade/GradeService";
import CategoryService from "@/services/product/category/CategoryService";
import SubCategoryService from "@/services/product/subCategory/SubCategoryService";
import {required} from "vuelidate/lib/validators";

const grade = new GradeService();
const category = new CategoryService();
const subCategory = new SubCategoryService();
const course = new CourseService();

export default {
  validations: {
    course: {
      title: {required},
      subject_code: {required},
      image: {},
      description: {required},
      expiry_months: {required},
      category_id: {required},
      sub_category_id: {required},
      grade_id: {required},
      default_price : {required},
      position : {},
      rising_software_key : {},
      test_type : {required}
    }
  },
  data() {
    return{
      imgurl: null,
      months: [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36
      ],
      sub_categories:[],
      categories: [],
      grades:[],
      errors: [],
      course:{
        title: '',
        subject_code: '',
        image: null,
        description: '',
        category_id: null,
        sub_category_id: null,
        grade_id: null,
        default_price: '',
        rising_software_key: '',
        test_type: '',
        state_price: '',
        availiability_state: '',
        visibility: '',
        status: ''
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      }
    }
  },
  methods:{
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.course) {
        if (key === "image" && this.course[key] != null && this.course[key] != undefined) {
          formData.append('image', this.course[key]);
        }
        else if (key == "state_price") {
          formData.append("state_price", this.course.state_price ? 'state' : 'default');
        }
        else if (key == "availiability_state") {
          formData.append("availiability_state", this.course.availiability_state ? 'available' : 'not_available');
        }
        else if (key == "visibility") {
          formData.append("visibility", this.course.visibility ? 'visible' : 'not_visible');
        }
        else if (key == "status") {
          formData.append("status", this.course.status ? 'active' : 'in_active');
        }
        else {
          if(this.course[key]){
            formData.append(key,this.course[key]);
          }
        }
      }
      return formData;
    },
    createCourse(){
      this.$v.$touch()
      if(this.$v.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        let formData = this.convertToFormData();
        this.errors = [];
        course
            .create(formData)
            .then(response => {
              this.$snotify.success("Course added");
              this.$router.push({
                name: "course"
              });
            })
            .catch((error) => {
              this.errors = error.response.data.errors;
            });
        }
      },
    previewImage(){
      if(this.course.image != null){
        this.imgurl= URL.createObjectURL(this.course.image)
      }else{
        this.imgurl = null;
      }
    },
    getAllGrades(){
      grade
          .getBySubCategory(this.course.sub_category_id)
          .then(response => {
            this.grades=response.data;
          })
          .catch((err) => {

          });
    },
    getCategories(){
      category
          .get()
          .then(response => {
            this.categories=response.data;
          })
          .catch((err) => {

          })
    },
    getSubCategory(){
      this.grades= [];
      subCategory
          .getByCategory(this.course.category_id)
          .then(response => {
            this.sub_categories=response.data;
          })
          .catch((err) => {

          })
    },
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    statePriceLabel: {
      // getter
      get: function () {
        if(this.course && this.course.state_price==true){
          return this.statePriceLabel="Enabled";
        }else{
          return this.statePriceLabel="Disabled";
        }
      },
      // setter
      set: function (newValue) {
        return newValue;if(this.course && this.course.state_price==true){
          return this.statePriceLabel="Enabled";
        }else{
          return this.statePriceLabel="Disabled";
        }
      }
    },
    availabilityStateLabel: function(){
      if(this.course && this.course.availiability_state==true){
        return this.statePriceLabel="Available"
      }else{
        return this.statePriceLabel="Not Available"
      }
    },
    visibilityLabel: function(){
      if(this.course && this.course.visibility==true){
        return this.visibility="Visible"
      }else{
        return this.visibility="Not Visible"
      }
    },
    statusLabel: function(){
      if(this.course && this.course.status==true){
        return this.status="Active"
      }else{
        return this.status="Inactive"
      }
    },
  }
}
</script>

<style scoped>
  #logo_preview{
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
</style>